import React, { useEffect, useState, forwardRef } from "react";

import Screenshot from "assets/img/hd-screenshot.png";
import ScreenshotDark from "assets/img/hd-screenshot-dark.png";
import { Icon } from "@iconify/react";
import { Button } from "components/ui/button";
import { Link } from "react-router-dom";
import BrowserWindowComponent from "components/molecules/BrowserWindowComponent";

interface HeroProps {
  scrollPosition: number;
}

const Hero = forwardRef<HTMLElement, HeroProps>(({ scrollPosition }, ref) => {
  
  const initialState = {
    scale: 1,
    skew: 0,
    translateY: 0,
  };

  const maxValues = {
    scale: 0.8,
    skew: 0.0004,
    translateY: -100,
  };

  const interpolate = (start: number, end: number, factor: number) => start + (end - start) * factor;

  const [transformValue, setTransformValue] = useState<string>(
    `matrix3d(${initialState.scale},0,0,0,0,${initialState.scale},0,${-initialState.skew},0,0,1,0,0,${initialState.translateY},0,1)`
  );

  useEffect(() => {
    const scale = interpolate(initialState.scale, maxValues.scale, scrollPosition);
    const skew = interpolate(initialState.skew, maxValues.skew, scrollPosition);
    const translateY = interpolate(initialState.translateY, maxValues.translateY, scrollPosition);

    setTransformValue(`matrix3d(${scale},0,0,0,0,${scale},0,${-skew},0,0,1,0,0,${translateY},0,1)`);
  }, [scrollPosition, initialState.scale, initialState.skew, initialState.translateY, maxValues.scale, maxValues.skew, maxValues.translateY]);

// const transformValue = `matrix3d(${0.8 + scrollPosition * 0.2}, 0, 0, 0, 0, ${0.8 + scrollPosition * 0.2}, 0, -${0.0005 + scrollPosition * 0.001}, 0, 0, 1, 0, 0, -${60 * (1 - scrollPosition)}, 0, 1)`;


  return (
    <>
      <div className="container z-10 px-4 mx-auto relative mb-10 mt-20 md:mt-28">
        <div className="text-start md:text-center md:mb-10 ">
          <div className="flex flex-col gap-y-2 justify-center md:items-center h-full">
            <h1 className="text-balance !leading-tight mb-6 md:max-w-[28ch]">
              Innovación y Sostenibilidad  para un Futuro mejor
            </h1>
            <div className="relative ">

            <h2 className="text-lg max-w-[45ch] text-balance font-light leading-normal bg-gradient-to-r from-orange-400 to-blue-500 bg-clip-text mix-blen d-color-burn text-gray-300">
              Optimizando la Gestión del Agua y Preservando el Patrimonio de la
              Huerta de Valencia
            </h2>
            </div>
            <div className="flex flex-col md:flex-row gap-5 mt-6 items-start md:items-center">
              <Button asChild variant="accent" size="lg" className="text-base">
                <Link to="/about">Descubre más</Link>
              </Button>

              <Button
                onClick={() => {}}
                variant="ghost"
                size="lg"
                className="text-base space-x-2">
                <Icon className="size-5" icon="heroicons-outline:play" />
                <span>Ver Video</span>
              </Button>
            </div>
          </div>
        </div>
        <div className="h-1/2 w-full translate-y-8 md:translate-y-0 scale-110 md:scale-100 mx-auto max-w-4xl md:max-w-5xl">
          <BrowserWindowComponent
            darkMode={true}
            image={Screenshot}
            darkImage={ScreenshotDark}
            alt="Hero Background"
            style={{ transform: transformValue }}

          />

          {/* <img
            src={Screenshot}
            alt="Screenshot"
            className="w-[100%] h-[200%] md:max-w-[90%] md:h-auto object-contain md:mx-auto scale-125 md:scale-100 md:mt-1/4 translate-y-12"
          /> */}
        </div>
      </div>
      <svg
        width="0"
        height="0"
        viewBox="0 0 1440 867"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <defs>
          {/* <clipPath id="myCurve" clipPathUnits="objectBoundingBox">
            <path
              d="M 0,1
						L 0,0
						L 1,0
						L 1,1
						C .65 .8, .35 .8, 0 1
						Z"
            />
          </clipPath> */}
          <clipPath id="divider" clipPathUnits={"objectBoundingBox"}>
            <path
              d="M0,0.847 C0,0.847,0.34,1,1,0.913 L1,0 L0,0 L0,0.847"
              fill="black"
            />
          </clipPath>
        </defs>
      </svg>
      {/* <span
        style={{
          background:
            "radial-gradient(ellipse at 50% 25%, rgba(0, 0, 0, 0) 0%, rgba(0, 188, 212, 0) 60%, rgba(0, 0, 0, 1) 160%)",
        }}
        className="pointer-events-none absolute block bottom-0 z-10 w-full h-screen bg-gradi ent-to-t from-bl ack/50 to-transpa rent inset-x-0"></span> */}
    </>
  );
});

export default Hero;