export default function Divider({ variant = "blue", className = "" }: { variant?: "blue" | "green", className?: string}) {
	return (
		<div className="relative z-[1] bg-gray-900 h-0 ">

		<div 
		 className={`absolute ${className} ${variant === "blue" && "bg-blue-500 border-t-blue-700 border-b-blue-300"} 
		 ${variant === "green" && "bg-green-500 border-b-green-700 border-t-green-300"} border-y-8 h-80 -translate-y-1/2 md:-translate-y-full  -skew-y-3 2xl:-skew-y-2 w-screen`}
		 >
		</div>
			 </div>
		// <div className="pb-28 scale-[200%] lg:scale-[150%] divider"
		// 	style={{
		// 		"background": `linear-gradient(${backgroundTop ?? "transparent"} 50%, ${backgroundBottom ?? "transparent"} 50%)`
		// 	}}>
		// 	{
		// 		(variant == "green" && <GreenBackground />) ||
		// 		(variant == "blue" && <BlueBackground />)
		// 	}
		// </div>
	)
}