

const BrowserWindowComponent = ({
  image,
  alt,
  darkMode,
  darkImage,
  className,
  style,
}: {
  image: string;
  darkImage: string;
  alt: string;
  darkMode?: boolean;
  className?: string;
  style?: any;
}) => {

  return (
    <div 
    style={style}    
    className={`mockup-browser smooth-shadow-light dark:smooth-shadow-dark  rounded-2xl overflow-x-auto overflow-hidden relative border 
       bg-gray-50 border-gray-100
    ${darkMode && 'dark:bg-gray-1000 dark:border-gray-800'}
      
    `}>
        <div className={`mockup-browser-toolbar before:opacity-10 before:dark:opacity-20 before:scale-90 !my-2`}>
          <div className={`input !h-6 text-xs select-none flex justify-center items-center text-center 
          text-gray-600 bg-gray-200
              ${darkMode && 'dark:text-gray-600 dark:bg-gray-950'}

          
           rounded-md`}>
            <span>hortatech.es/dashboard</span>
          </div>
        </div>
        <div className="flex justify-center  bg-base-200">
          <div className="relative ">

            <img
              src={image}
              alt={alt}
              className="w-full block dark:hidden h-full object-cover"
            />
            <img
              src={darkImage}
              alt={alt}
              className="w-full hidden dark:block h-full object-cover"
            />
          </div>
        </div>
      </div>
  );
}


export default BrowserWindowComponent;