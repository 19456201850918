// Components
import { useEffect, useState, useRef } from "react";
import Icon from "components/Icon";
import { Link } from "react-router-dom";
import { Button } from "components/ui/button";
import ObservableSection from "components/ObservableSection";
import TableOfContents from "components/TableOfContents";
import Divider from "components/sections/Divider";

// sections
import Hero from "./Hero";
import FourPointShowcase from "components/featureSections/FourPointShowcase";
import SwitchableFeatureShowcase from "components/featureSections/SwitchableFeatureShowcase";
import CarouselFeatureSwitcher from "components/featureSections/CarouselFeatureSwitcher";
import ThreePointShowcase from "components/featureSections/ThreePointShowcase";
import SixPointImageShowcase from "components/featureSections/SixPointImageShowcase";
import TabbedFourPointShowcase from "components/featureSections/TabbedFourPointShowcase";
import FlowInCards from "components/featureSections/FlowInCards";
import Contacts from "components/sections/Contacts";

// Assets
import ODSImg4 from "assets/img/ods/S-WEB-Goal-04.png";
import ODSImg6 from "assets/img/ods/S-WEB-Goal-06.png";
import ODSImg10 from "assets/img/ods/S-WEB-Goal-10.png";
import ODSImg17 from "assets/img/ods/S-WEB-Goal-17.png";

import Carousel5 from "assets/img/front/hero-bg.jpeg";
import ShowcaseImage from "assets/img/front/showcase_1.jpeg";
import ShowcaseImage2 from "assets/img/front/showcase_2.jpeg";
import CharacteristicsDigitalManagement from "assets/img/characteristics/char-digital-management.webp";
import CharacteristicsSocial from "assets/img/characteristics/char-social.webp";
import CharacteristicsSustainability from "assets/img/characteristics/char-sustainability.webp";
import CharactersiticsRealTime from "assets/img/characteristics/char-real-time.webp";
import Carousel1 from "assets/img/front/carousel-1.jpeg";
import Carousel2 from "assets/img/front/carousel-2.jpeg";
import Carousel3 from "assets/img/front/carousel-3.jpeg";
import HeroBackground from "assets/img/front/img-hero.jpg";
import Stock1 from "assets/iStock-1138665866.jpg";
// @ts-ignore
// import SustainableVideo from "sustainable-video.mp4";
import Stock3 from "assets/img/front/foto-area.jpg";
import LogoLasNaves from "assets/img/partners/logo-las-naves.webp";
import LogoAyuntamiento from "assets/img/partners/logo-ayuntamiento-valencia.svg";
import LogoTheRoot from "assets/img/partners/logo-the-root.webp";
import LogoUnescoValencia from "assets/img/partners/logo-unesco.webp";
import LogoTribunalLasAguas from "assets/img/partners/logo-tribunal-las-aguas.webp";
import AnimatedTabs, { type TabsItem } from "components/molecules/AnimatedTabs";
import { ImageFrame } from "components/ui/ImageFrame";

const Home = () => {
  let [sections, setSections] = useState<string[]>([]);
  let [activeSection, setActiveSection] = useState<string>();

  const tabsItems: TabsItem[] = [
    {
      icon: LogoLasNaves,
      key: "lasnaves",
      name: "Las Naves",
      link: "https://www.lasnaves.com/proyectos/horta-tech/?lang=es",
      content:
        "Hortatech fue creado dentro de Col·lab, la aceleradora pública de Las Naves, el centro de innovación del Ayuntamiento de Valencia. Esta colaboración nos proporciona acceso a recursos, experiencia y redes que mejoran el impacto y alcance del proyecto.",
    },
    {
      icon: LogoAyuntamiento,
      key: "ayuntamiento",
      name: "Ayuntamient de València",
      link: "https://www.valencia.es",
      content:
        "El “Tribunal de las Aguas de la Vega de Valencia”, más conocido por su denominación abreviada de “Tribunal de las Aguas” es, sin duda alguna, la más antigua de las instituciones de justicia existentes en Europa. Institución a la que vamos a ofrecer nuestro producto disruptivo e innovador para modernizar la gestión del agua de riego y ser una institución pionera en el mundo, aunando impactos ambientales, económicos y sociales. De esta forma podrá tener un control mas profundo y preciso de la gestión del consumo del agua de riego cara a aumentar el ahorro y optimizar las infraestructuras históricas.",
    },
    {
      icon: LogoUnescoValencia,
      key: "unesco",
      name: "UNESCO Valencia",
      link: "https://valenciamediterraneo.es/",
      content:
        "UNESCO Valencia Centro del Mediterráneo es una institución asociada a la Organización de las Naciones Unidas para la Educación, la ciencia y la Cultura. Responsable por la inclusión del Tribunal de las Aguas en la Lista Representativa del Patrimonio Cultural Inmaterial de la Humanidad.",
    },
    {
      icon: LogoTribunalLasAguas,
      key: "tribunal",
      name: "Tribunal de las Aguas",
      link: "https://www.tribunaldelasaguas.org",
      content:
        "El “Tribunal de las Aguas de la Vega de Valencia”, más conocido por su denominación abreviada de “Tribunal de las Aguas” es, sin duda alguna, la más antigua de las instituciones de justicia existentes en Europa. Institución a la que vamos a ofrecer nuestro producto disruptivo e innovador para modernizar la gestión del agua de riego y ser una institución pionera en el mundo, aunando impactos ambientales, económicos y sociales. De esta forma podrá tener un control mas profundo y preciso de la gestión del consumo del agua de riego cara a aumentar el ahorro y optimizar las infraestructuras históricas.",
    },
    {
      icon: LogoTheRoot,
      key: "theroot",
      name: "We The Root",
      link: "https://wetheroot.com",
      content:
        "The Root es una empresa de tecnología especializada en el desarrollo de soluciones innovadoras para la gestión del agua y la agricultura sostenible. Hortatech colabora con The Root para implementar tecnologías avanzadas de monitoreo y control en la Huerta de Valencia.",
    },

    // {
    //   icon: LogoSIPAM,
    //   key: "sipam",
    //   name: "Sistema de Información de Patrimonio Agrícola Mundial",
    //   link: "https://www.fao.org/giahs/es/",
    //   content:
    //     "El Sistema de Información de Patrimonio Agrícola Mundial (SIPAM) es una iniciativa de la Organización de las Naciones Unidas para la Alimentación y la Agricultura (FAO) que tiene como objetivo promover y proteger el patrimonio agrícola mundial. SIPAM trabaja en estrecha colaboración con comunidades agrícolas, expertos y organizaciones internacionales para identificar, documentar y conservar las prácticas agrícolas tradicionales y sostenibles que son fundamentales para la seguridad alimentaria y la preservación de la biodiversidad. Hortatech se enorgullece de ser parte de SIPAM y contribuir a la preservación de la huerta de Valencia y sus técnicas agrícolas tradicionales.",
    // },
    // {
    //   icon: LogoMissionsValencia2030,
    //   key: "missions",
    //   name: "Missions Valencia 2030",
    //   link: "https://www.missionsvalencia.eu/?lang=en",
    //   content:
    //     "UNESCO Valencia Centro del Mediterráneo es una institución asociada a la Organización de las Naciones Unidas para la Educación, la ciencia y la Cultura. Responsable por la inclusión del Tribunal de las Aguas en la Lista Representativa del Patrimonio Cultural Inmaterial de la Humanidad.",
    // },
  ];

  const updateTOC = (isInView: boolean, id: string) => {
    if (isInView) setActiveSection(id);
    else if (!sections.find((s) => s === id)) setSections([...sections, id]);
  };

  const [scrollPosition, setScrollPosition] = useState(0);

  console.log("scrollPosition in home", scrollPosition);


  const [minY, setMinY] = useState(0);
  const [maxY, setMaxY] = useState(window.innerHeight);

  const heroRef = useRef<HTMLElement | null>(null);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const maxScroll = maxY - minY;
    const scrollPercent = Math.min((scrollTop - minY) / maxScroll, 1);
    setScrollPosition(scrollPercent);
  };
useEffect(() => {
    if (heroRef.current) {
      const rect = heroRef.current.getBoundingClientRect();
      setMinY(rect.top + window.scrollY);
      setMaxY(rect.bottom + window.scrollY);
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);


  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  console.log("scrollPosition in home", scrollPosition);



  return (
    <>
      <TableOfContents
        sections={sections}
        activeSection={activeSection!}
        className="fixed bottom-0 right-0 z-50"
      />
      <ObservableSection
        className="h-screen overfl ow-y-clip pt-10 text-white flex-col relative  bg-cover bg-no-repeat pb-8 rou nded-bl-[50%] md:roun ded-br-[50%] rounded-br-none overflow-x-clip"
        //   style={{
        //     backgroundImage: `
        // linear-gradient(
        // 	rgba(0, 0, 0, 0.8),
        // 	rgba(0, 0, 0, 0.6)
        // ),
        // url(${HeroBackground})`,
        //     clipPath: "url(#divider)",
        //   }}
        // onScroll={handleScroll}
        onVisibilityChange={updateTOC}
        id="Inicio">
        <span
          style={{
            backgroundImage: `
        linear-gradient(
          rgba(0, 0, 0, 0.8), 
          rgba(0, 0, 0, 0.6)
        ),
        url(${HeroBackground})`,
            clipPath: "url(#divider)",
          }}
          className="absolute bg-cover bg-no-repeat inset-0 ins et-0 
          "></span>
        {/* <span className="absolute backdrop-blur-sm  inset-0 ">

          </span> */}
        <Hero ref={heroRef} scrollPosition={scrollPosition} />
        </ObservableSection>

      <ObservableSection onVisibilityChange={updateTOC} id="Visión">
        <div className="mt-28 md:mt-40">
          <h2 className="text-center">Visión</h2>
          <p className="text-center md:max-w-3xl mx-auto">
            HortaTech es una iniciativa destinada a optimizar la gestión de
            recursos hídricos y preservar el patrimonio cultural y natural de la
            Huerta de Valencia. Colaboramos con empresas y organizaciones
            líderes para revolucionar el uso y la conservación del agua mediante
            soluciones tecnológicas innovadoras y la promoción de una economía
            circular.
          </p>
          <ImageFrame src={ShowcaseImage} alt="Visión" />
        </div>
      </ObservableSection>

      <ObservableSection onVisibilityChange={updateTOC} id="Metas & Objetivos">
        <div>
          <h2 className="text-center lg:-translate-x-28 lg:translate-y-24">
            Metas & Objetivos
          </h2>

          <FourPointShowcase
            image={ShowcaseImage2}
            items={[
              {
                icon: "time-measurement-simple",
                title: "Optimizar la Gestión del Agua",
                content:
                  "Lograr la sostenibilidad a largo plazo en la gestión de recursos hídricos.",
              },
              {
                icon: "maintain-patrimony",
                title: "Preservar el Patrimonio Cultural",
                content:
                  "Promover y conservar el patrimonio cultural y natural de la huerta.",
              },
              {
                icon: "social-growth",
                title: "Crecimiento Social y Económico",
                content:
                  "Fomentar el crecimiento económico, ahorro energético y bienestar social a través de prácticas eficientes de gestión del agua.",
              },
              {
                icon: "social-conscience",
                title: "Conciencia Social",
                content:
                  "Crear conciencia sobre el valor del agua y la importancia histórica de las infraestructuras hidráulicas.",
              },
            ]}></FourPointShowcase>
        </div>
      </ObservableSection>
      <ObservableSection
        onVisibilityChange={updateTOC}
        id="Caracteristicas Clave">
        <div>
          <div className="flex flex-col gap-4 lg:px-20 px-4 md:px-8 lg:text-center my-6">
            <h2>Caracteristicas Clave y beneficios</h2>
            <p>
              HortaTech es una iniciativa ambiciosa y transformadora destinada a
              optimizar la gestión de recursos hídricos y preservar el
              patrimonio cultural y natural de la Huerta de Valencia.
              Colaboramos con empresas y organizaciones líderes para
              revolucionar la gestión del agua de riego.
            </p>
          </div>
          <SwitchableFeatureShowcase
            features={[
              {
                title: "Monitoreo y Control en Tiempo Real",
                subtitle:
                  "Uso de sensores y sistemas de telemetría para datos precisos.",
                description:
                  "Utilizamos una solución innovadora de control en tiempo real que permite una gestión eficiente y sostenible de los recursos hídricos. Esta tecnología nos permite optimizar los costos operativos y de mantenimiento, al tiempo que aseguramos el funcionamiento efectivo de la infraestructura hídrica.",
                icon: "real-time-sensor",
                image: CharactersiticsRealTime,
              },
              {
                title: "Gestión Digital del Patrimonio del Agua",
                subtitle:
                  "Plataforma para la gestión integral del ciclo del agua.",
                description:
                  "Hortatech tiene como objetivo desarrollar herramientas avanzadas para la preservación, difusión y gestión digital del valioso patrimonio humano, paisajístico y cultural de la huerta. A través de la digitalización y los principios de la economía circular, aprovechamos el valor digital del patrimonio hidráulico para promover el turismo cultural y la apreciación entre los ciudadanos.",
                icon: "digital-patrimony",
                image: CharacteristicsDigitalManagement,
              },
              {
                title: "Enfoque Social",
                subtitle: "Programas educativos y participación comunitaria.",
                description:
                  "Hortatech se esfuerza por crear conciencia en la sociedad sobre el valor del agua y la importancia de los agricultores en la Huerta de Valencia. Promovemos la accesibilidad universal para todos los usuarios y enfatizamos la importancia histórica y patrimonial de las infraestructuras hidráulicas para fomentar un sentido de orgullo y conexión dentro de la comunidad.",
                icon: "social-focus",
                image: CharacteristicsSocial,
              },
              {
                title: "Sostenibilidad Ambiental",
                subtitle:
                  "Implementación de tecnologías de riego sostenibles y conservación de la biodiversidad.",
                description:
                  "Mediante la implementación de tecnologías de sensores basadas en fibra óptica y un panel de control digital integral para la gestión del ciclo del agua, contribuimos activamente a los esfuerzos de conservación del agua. Nuestro objetivo es monitorear la calidad del agua, preservar los espacios naturales y parques asociados a la huerta y establecer sistemas de riego sostenibles que se alineen con los principios de flujo ecológico.",
                icon: "global-warming",
                image: CharacteristicsSustainability,
              },
            ]}></SwitchableFeatureShowcase>
        </div>
      </ObservableSection>
      <ObservableSection
        onVisibilityChange={updateTOC}
        className="bg-gray-100 dark:bg-gray-950 flex flex-col"
        id="Gestión digital del Agua">
        <div className="z-10 ">
          <h2 className="text-center">Gestión digital del Agua</h2>
          <p className="text-center">
            HortaTech es una iniciativa ambiciosa y transformadora destinada a
            optimizar la gestión de recursos hídricos y preservar el patrimonio
            cultural y natural de la Huerta de Valencia. Colaboramos con
            empresas y organizaciones líderes para revolucionar.
          </p>
          <div>
            {/* <h3 className="text-center">Importancia en la Huerta de Valencia</h3> */}

            <CarouselFeatureSwitcher
              options={{ loop: true }}
              sideBlur={false}
              variant="blurred-bg"
              items={[
                {
                  title: "Sostenibilidad Agrícola",
                  content:
                    "Administrar y garantizar la disponibilidad constante de agua para mantener la productividad agrícola a largo plazo",
                  header: (
                    <img
                      src={Carousel1}
                      alt="Sostenibilidad Agrícola"
                      className="h-56 w-full object-cover"
                    />
                  ),
                },
                {
                  title: "Preservación del Ecosistema",
                  content:
                    "Mantener los ecosistemas naturales, proteger y garantizar el caudal ecologico en zonas humedas",
                  header: (
                    <img
                      src={Carousel2}
                      alt="Preservación del Ecosistema"
                      className="h-56 w-full object-cover"
                    />
                  ),
                },
                {
                  title: "Protección del Patrimonio Cultural del Agua",
                  content:
                    "Preservar infraestructuras hidráulicas históricas cruciales para la región",
                  header: (
                    <img
                      src={Carousel3}
                      alt="Protección del Patrimonio Cultural del Agua"
                      className="h-56 w-full object-cover"
                    />
                  ),
                },
                {
                  title: "Adaptación al Cambio Climático",
                  content:
                    "Enfrentar los desafíos del cambio climático con el acceso a datos que facilitan una gestión eficiente del agua",
                  header: (
                    <img
                      src={Carousel1}
                      alt="Adaptación al Cambio Climático"
                      className="h-56 w-full object-cover"
                    />
                  ),
                },
                {
                  title:
                    "Acceso a la informacion para un uso Responsable de los Recursos",
                  content:
                    "Generación de información para implementar  técnicas de riego sostenibles y fomentar la conciencia sobre el uso responsable del agua",
                  header: (
                    <img
                      src={Carousel5}
                      alt="Acceso a la informacion para un uso Responsable de los Recursos"
                      className="h-56 w-full object-cover"
                    />
                  ),
                },
              ]}
            />
          </div>
          <Button
            asChild
            variant="ghost"
            className={"z-10 bg-white dark:bg-gray-950 mx-auto"}>
            <Link to="/water-management">
              Descubre más
              <Icon name="arrow" className="rotate-180" />
            </Link>
          </Button>
        </div>
      </ObservableSection>
      <Divider variant="blue" />

      <ObservableSection
        onVisibilityChange={updateTOC}
        className="z-10"
        id="Impacto Social">
        <div>
          {/* <Heading title="Impacto Social" subtitle="HortaTech es una iniciativa ambiciosa y transformadora destinada a optimizar la gestión de recursos hídricos y preservar el patrimonio cultural y natural de la Huerta de Valencia. Colaboramos con empresas y organizaciones líderes para revolucionar." /> */}
          <h2 className="text-center">Impacto Social</h2>
          <p className="text-center max-w-3xl px-6 mx-auto">
            HortaTech es una iniciativa ambiciosa y transformadora destinada a
            optimizar la gestión de recursos hídricos y preservar el patrimonio
            cultural y natural de la Huerta de Valencia. Trabajamos por alinear
            HortaTech con los Objetivos de Desarrollo Sostenible de la ONU.
          </p>
          {/* <h3 className="text-center">Implementacíon</h3> */}
          {/* <CarouselFeatureSwitcher
            variant="active-indicator"
            items={[
              {
                title: "Sensores y Estaciones de Monitoreo",
                content:
                  "Instalación de sensores en puntos estratégicos para recopilar datos sobre el agua",
                header: <Icon name="water-recycle" className="size-24 mt-4" primaryColor="green-700" secondaryColor="green-950" />,
              },
              {
                title: "Preservación del Ecosistema",
                content:
                  "Mantener los ecosistemas naturales y proteger la biodiversidad",
                header: <Icon name="water-recycle" className="size-24 mt-4" primaryColor="green-700" secondaryColor="green-950" />,
              },
              {
                title: "Protección del Patrimonio Cultural",
                content:
                  "Preservar infraestructuras hidráulicas históricas cruciales para la región",
                header: <Icon name="water-recycle" className="size-24 mt-4" primaryColor="green-700" secondaryColor="green-950" />,
              },
            ]}
          >
            </CarouselFeatureSwitcher> */}
          <div className="max-w-2xl mx-auto">
            <FlowInCards
              items={[
                {
                  title: "Programas Educativos",
                  img: ODSImg4,
                  content:
                    "Para diferentes grupos de edad sobre la importancia del agua y su uso eficiente.",
                },
                {
                  title: "Prácticas Sostenibles",
                  img: ODSImg6,
                  content:
                    "Fomento de prácticas sostenibles en el uso del agua",
                },
                {
                  title: "Participación Comunitaria",
                  img: ODSImg10,
                  content:
                    "Actividades para involucrar a la comunidad en la gestión del agua",
                },
                {
                  title: "Alianzas Locales",
                  img: ODSImg17,
                  content:
                    "Colaboraciones con organizaciones locales para fortalecer el impacto de las campañas",
                },
              ]}
            />
            <div className="flex w-full justify-center  gap-4">
              <Button asChild variant="secondary" className="">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.un.org/sustainabledevelopment/es/objetivos-de-desarrollo-sostenible/">
                  Descubre los ODS
                </a>
              </Button>
            </div>
          </div>

          <h3 className="text-center mt-8">
            Promoción del Patrimonio Histórico
          </h3>
          <ThreePointShowcase
            // title="Promoción del Patrimonio Histórico"
            items={[
              {
                title: "Significado Histórico",
                content:
                  "Destacar el valor cultural, paisagistico y funcional de las infraestructuras hidráulicas",
                image: ShowcaseImage,
              },
              {
                title: "Educacion patrimonial",
                content:
                  "Educacion patrimonial a travez del acceso real a los datos",
                image: Stock1,
              },
              {
                title: "Colaboración con Instituciones Culturales locales",
                content:
                  "Desarollo de proyectos en colaboracion con las asociaciones culturales y organismos publicos",
                image: Stock3,
              },
            ]}
          />
          <Button asChild variant="default" className="w-auto mx-auto">
            <Link to="/social-impact">
              Descubre el impacto social
              <Icon name="arrow" className="size-6 rotate-180" />
            </Link>
          </Button>
        </div>
      </ObservableSection>
      <ObservableSection onVisibilityChange={updateTOC} id="Impacto Ambiental">
        <div className="z-10">
          <h2 className="text-center">Impacto Ambiental</h2>
          <p className="text-center">
            HortaTech es una iniciativa ambiciosa y transformadora destinada a
            optimizar la gestión de recursos hídricos y preservar el patrimonio
            cultural y natural de la Huerta de Valencia.
          </p>

          <SixPointImageShowcase
            items={[
              // {
              //   title: "Tecnologías avanzadas de riego",
              //   content:
              //     "Lograr la sostenibilidad a largo plazo en la gestión de recursos hídricos de la huerta",
              //   icon: "water-technologies",
              // },
              {
                title: "Conservación de la Biodiversidad",
                content:
                  "Salvaguardar la diversidad biológica en la Huerta de Valencia.",
                icon: "protect-biodiversity",
              },
              {
                title: "Reutilización y Reciclaje de Agua",
                content:
                  "Captación de agua de lluvia y reciclaje de aguas residuales",
                icon: "water-recycle",
              },
              // {
              //   title: "Educación y Sensibilización",
              //   content:
              //     "Programas educativos para agricultores y comunidades locales",
              //   icon: "public-education",
              // },
              {
                title: "Mejora de la Infraestructura Hídrica",
                content:
                  "Optimización de la infraestructura existente para reducir pérdidas de agua",
                icon: "infrastructure",
              },
              {
                title: "Sensibilización Comunitaria",
                content:
                  "Campañas para crear conciencia sobre la conservación del agua",
                icon: "social-focus",
              },
            ]}
            media={`${process.env.PUBLIC_URL}/sustainable-video.mp4`}
            // media={SustainableVideo}
          />
          <Button asChild variant="default" className="mx-auto">
            <Link to="/sustainability">
              Descubre más
              <Icon name="arrow" className="rotate-180" />
            </Link>
          </Button>
        </div>
      </ObservableSection>
      <Divider
        className="!-translate-y-1/2 md:!-translate-y-2/3 h-40 md:h-56"
        variant="green"
      />
      <ObservableSection
        onVisibilityChange={updateTOC}
        className="bg-gray-900 pt-40 text-white"
        id="Tecnología">
        <div>
          <div className="flex flex-col gap-4 lg:px-20 px-4 md:px-8 text-center my-6">
            <h2>Tecnología</h2>
            <p>
              HortaTech es una iniciativa ambiciosa y transformadora destinada a
              optimizar la gestión de recursos hídricos y preservar el
              patrimonio cultural y natural de la Huerta de Valencia.
              Colaboramos con empresas y organizaciones líderes para
              revolucionar.
            </p>
          </div>
          <TabbedFourPointShowcase
            tabs={[
              {
                name: "Soluciones Tecnológicas Avanzadas",
                items: [
                  {
                    title: "Sistemas de telemetría y control en tiempo real",
                    content:
                      "Implementamos sistemas de telemetría y control en tiempo real para monitorear y gestionar de manera eficiente los recursos hídricos. Estos sistemas nos permiten recopilar datos precisos sobre el flujo de agua, los niveles de humedad del suelo y otros parámetros relevantes. Utilizando esta información, podemos ajustar las operaciones de riego y garantizar un uso óptimo del agua.",
                    icon: "cycle-leaf",
                  },
                  {
                    title: "Sensores y dispositivos inteligentes",
                    content:
                      "Empleamos una variedad de sensores y dispositivos inteligentes para monitorear y recopilar datos en tiempo real sobre la calidad del agua, la temperatura, la humedad y otros indicadores relevantes. Estos dispositivos nos ayudan a detectar problemas potenciales, identificar tendencias y tomar decisiones informadas para garantizar la calidad del agua y la eficiencia en su uso.",
                    icon: "cycle-leaf",
                  },
                  {
                    title: "Plataformas de gestión de datos",
                    content:
                      "Utilizamos plataformas de gestión de datos para almacenar, analizar y visualizar la información recopilada por nuestros sistemas de monitoreo. Estas plataformas nos brindan una visión completa y en tiempo real de los datos, lo que nos permite tomar decisiones basadas en evidencia y optimizar nuestras operaciones.",
                    icon: "cycle-leaf",
                  },
                  {
                    title: "Modelos de predicción y análisis avanzado",
                    content:
                      "Aplicamos modelos de predicción y análisis avanzado para prever la demanda de agua, identificar patrones de uso y optimizar la planificación del riego. Estos modelos nos ayudan a optimizar el uso del agua y a garantizar una distribución equitativa y eficiente de los recursos hídricos en la Huerta de Valencia.",
                    icon: "cycle-leaf",
                  },
                  {
                    title: "Tecnología de información geográfica (GIS)",
                    content:
                      "Utilizamos tecnología de información geográfica para mapear y visualizar la distribución de los recursos hídricos, los sistemas de riego y otros elementos relevantes en la Huerta de Valencia. Esto nos permite tener una comprensión completa de la infraestructura hidráulica y optimizar su funcionamiento.",
                    icon: "cycle-leaf",
                  },
                ],
              },
              // {
              //   name: "Sensores de Fibra Ópticas",
              //   items: [
              //     {
              //       title:
              //         "Sensores de fibra óptica para la medición de temperatura",
              //       content:
              //         "Estos sensores utilizan cambios en la longitud de onda de la luz para medir la temperatura del agua. Se pueden implementar en diferentes puntos de la red de distribución de agua para monitorear y controlar la temperatura en tiempo real, lo que es especialmente importante para garantizar la seguridad y eficiencia de los sistemas de calefacción y refrigeración.",
              //       icon: "cycle-leaf",
              //     },
              //     {
              //       title:
              //         "Sensores de fibra óptica para la medición de turbidez",
              //       content:
              //         "La turbidez del agua es un indicador importante de su calidad y claridad. Los sensores de fibra óptica basados en la dispersión de la luz pueden medir la turbidez del agua al detectar las partículas suspendidas en ella. Estos sensores son utilizados para controlar y mantener la calidad del agua en ríos, embalses y sistemas de suministro de agua potable.",
              //       icon: "cycle-leaf",
              //     },
              //     {
              //       title: "Sensores de fibra óptica para la medición de pH",
              //       content:
              //         "El pH es otro parámetro crítico para determinar la calidad del agua. Los sensores de fibra óptica pueden ser diseñados para medir el pH mediante la utilización de recubrimientos sensibles a los cambios de acidez o alcalinidad. Estos sensores ofrecen una medición precisa y estable del pH del agua, lo que permite tomar medidas oportunas para mantener la calidad y evitar la corrosión en las infraestructuras hidráulicas.",
              //       icon: "cycle-leaf",
              //     },
              //     {
              //       title:
              //         "Sensores de fibra óptica para la detección de contaminantes",
              //       content:
              //         "Los sensores de fibra óptica pueden ser utilizados para detectar y medir diferentes contaminantes en el agua, como metales pesados, compuestos orgánicos y productos químicos tóxicos. Estos sensores se basan en la interacción entre la luz y los materiales específicos presentes en el agua, proporcionando una detección sensible y selectiva de los contaminantes.",
              //       icon: "cycle-leaf",
              //     },
              //   ],
              // },
              {
                name: "Panel de Control",
                items: [
                  {
                    title: "Monitoreo de variables",
                    content:
                      "El panel de control recopila datos en tiempo real de diversas variables relacionadas con el ciclo del agua, como caudal, presión, nivel de agua, calidad del agua y consumo. Estos datos son presentados de forma clara y visual, lo que permite una fácil interpretación y seguimiento de los parámetros clave.",
                    icon: "cycle-leaf",
                  },
                  {
                    title: "Alertas y notificaciones",
                    content:
                      "El panel de control está diseñado para generar alertas y notificaciones automáticas en caso de que se detecten anomalías o situaciones de riesgo. Esto permite una respuesta rápida y eficiente ante cualquier evento inesperado, como fugas, bajos niveles de agua o problemas en la calidad del agua.",
                    icon: "cycle-leaf",
                  },
                ],
              },
            ]}
          />
          <Button
            asChild
            variant="secondary"
            size="lg"
            className="mx-auto w-fit">
            <Link to="/technology" className="text-primary">
              Aprender más sobre la tecnología
            </Link>
          </Button>
        </div>
      </ObservableSection>

      <ObservableSection
        onVisibilityChange={updateTOC}
        id="Colaboraciones Clave">
        <div>
          <h2 className="lg:text-center">Colaboraciones Clave</h2>

          {/* <h3 className="lg:text-center">Nuestros partneres</h3> */}
          <AnimatedTabs items={tabsItems} />
          {/* 
          <h3 className="lg:text-center">Con soporte de</h3>
          <div className="flex flex-wrap justify-center gap-4">
            <img src={LogoTheRoot} alt="The Root" className="h-12" />
            <img src={LogoSIPAM} alt="SIPAM" className="h-12" />
          </div> */}
          <div className="mx-auto flex gap-4">
            {/* // TODO: Adjust where */}
            <Button asChild variant="accent" className="mx-auto">
              <Link to="/about-us">
                Conoce nuestro equipo
                {/* <Icon name="arrow" className="rotate-180" /> */}
              </Link>
            </Button>
            <Button asChild variant="default" className="mx-auto">
              <Link to="/partners">
                Descubre más
                <Icon name="arrow" className="rotate-180" />
              </Link>
            </Button>
          </div>
        </div>
      </ObservableSection>

      <section>
        <Contacts />
      </section>
    </>
  );
};

export default Home;
